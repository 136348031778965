const defaultState = {
  showReportIds: [],
  reportsById: {},
  fetchedAll: false
};

export default function triageTable(state = defaultState, action) {
  switch (action.type) {
    case 'INIT_TRIAGE_TABLE':
      const showReportIds = action.reports.map((report) => report.id);
      const reportsById = Object.assign({}, state.reportsById);
      action.reports.forEach((report) => {
        reportsById[report.id] = report;
      });
      return Object.assign({}, state, {
        showReportIds,
        reportsById
      });

    case 'NEW_REPORT':
      // prepend a new report if it's not already in the table
      if (state.showReportIds.includes(action.report.id)) {
        return state;
      }
      return Object.assign({}, state, {
        showReportIds: [action.report.id].concat(state.showReportIds),
        reportsById: Object.assign({}, state.reportsById, {
          [action.report.id]: action.report
        })
      });

    case 'UPDATE_REPORTS':
      return Object.assign({}, state, {
        reportsById: Object.assign({}, state.reportsById, {
          [action.report.id]: action.report
        })
      });

    case 'FETCHED_REPORTS':
      if (action.reports.length === 0) {
        return Object.assign({}, state, {
          fetchedAll: true
        });
      }

      const newReportIds = action.reports.map((r) => r.id);
      const newReportsById = {};
      action.reports.forEach((report) => {
        newReportsById[report.id] = report;
      });
      const insertionIndex = state.showReportIds.indexOf(action.afterId) + 1;
      return Object.assign({}, state, {
        showReportIds: [
          ...state.showReportIds.slice(0, insertionIndex),
          ...newReportIds,
          ...state.showReportIds.slice(insertionIndex)
        ],
        reportsById: Object.assign({}, state.reportsById, newReportsById)
      });

    default:
      return state;
  };
};
