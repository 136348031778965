import { createStore } from 'redux';

import reducers from 'src/reducers';

const store = window.store == null ? createStore(reducers) : window.store;
window.store = store;

export default store;

export function NotifyUser(args) {
  const notificationID = `${Date.now()}`;
  store.dispatch({
    type: 'NOTIFY_USER',
    message: args.message,
    notificationID
  });

  if (args.timeoutMs) {
    setTimeout(() => {
      store.dispatch({
        type: 'DISMISS_NOTIFICATION',
        notificationID
      });
    }, args.timeoutMs);
  }
}
