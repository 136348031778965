import React from 'react';

import { connect } from 'react-redux';
import store from "src/store";

function mapAppStateToProps(appState) {
  return appState.notifications;
}

class Notifications extends React.Component {
  render() {
    const { notificationsByID, notificationIDs } = this.props;

    const notifications = notificationIDs.map(id => {
      return notificationsByID[id];
    });

    return (
      <>
        {notifications.map(notification => (
          <Notification key={notification.id} {...notification} />
        ))}
      </>
    );
  }
}

class Notification extends React.Component {
  dismiss = (event) => {
    event.preventDefault();
    store.dispatch({
      type: "DISMISS_NOTIFICATION",
      notificationID: this.props.id
    });
  };

  render() {
    const { message, dismissed } = this.props;

    const classNames = [
      "alert",
      "alert-info",
      "Notifications-notification",
      dismissed && "dismissed",
    ]
      .filter(Boolean)
      .join(" ");

    return (
      <div className={classNames}>
        <strong>{message}</strong>
        <button type="button" className="close" onClick={this.dismiss}>&times;</button>
      </div>
    );
  }
}

export default connect(mapAppStateToProps)(Notifications);
