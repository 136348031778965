const defaultState = {
  notificationsByID: {},
  notificationIDs: [],
};

export default function notifications(state = defaultState, action) {
  switch (action.type) {
    case 'NOTIFY_USER':
      return {
        ...state,
        notificationsByID: {
          ...state.notificationsByID,
          [action.notificationID]: {
            id: action.notificationID,
            message: action.message,
            dismissed: false
          }
        },
        notificationIDs: [
          ...state.notificationIDs,
          action.notificationID
        ]
      };

    case 'DISMISS_NOTIFICATION':
      return {
        ...state,
        notificationsByID: {
          ...state.notificationsByID,
          [action.notificationID]: {
            ...state.notificationsByID[action.notificationID],
            dismissed: true
          }
        }
      };

    default:
      return state;
  }
}
