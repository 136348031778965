import triageTableReducer from './triageTable';
import notificationsReducer from './notifications';

const defaultState = {
  triageTable: undefined,
  notifications: undefined
};

export default function reducers(state = defaultState, action) {
  return {
    triageTable: triageTableReducer(state.triageTable, action),
    notifications: notificationsReducer(state.notifications, action)
  };
}
