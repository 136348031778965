import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';

import channelConsumer from 'src/channels/consumer';
import store from 'src/store';
import Notifications from 'src/components/Notifications';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('notifications');
  ReactDOM.render(
    <Provider store={store}>
      <Notifications />
    </Provider>,
    container
  );
});

